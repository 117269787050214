










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IStrDetailValue,
  IStrValue,
  ISelectItem,
  IFullText,
  IFullTextBase,
  IInfo
} from "@/Services/Interfaces";
import {
  globalUpdateDetailService,
  globalInfoService
} from "@/Services/GlobalService";
import { InfoType, CrudType } from "@/Services/enums";
import ActionComponent from "./ActionComponent.vue";
import BaseSubComponent from "./BaseSubComponent.vue";
import { dataService } from "@/Services/DataServices";
import store from "@/store";

@Component({ components: { ActionComponent, BaseSubComponent } })
export default class SelectComponent extends BaseSubComponent {
  count: any;
  autovalue: any;
  entries: IFullText[];
  isLoading: any;
  descriptionLimit: any;
  private strsearch: string = "";
  thismodels: any;
  thismodel: IStrDetailValue;
  isactivated : boolean = false;;

  constructor() {
    super();
    this.name = "AutoCompleteComponent";
    this.canAction = false;
    this.model = <IStrDetailValue>{};
    this.thismodel = <IStrDetailValue>{};
    this.autovalue = null;
    this.isLoading = false;
    this.entries = new Array<IFullText>();
  }

  onUpdate(model: any) {
    if (this.canUpdate(this.thismodel)) {
      if (this.thismodel.valuetyp.toLowerCase() == "innerlink") {
        if (model.base_id == undefined) {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Bitte auch eine Verknüpfung auswählen.. nicht wahr.",
            type: InfoType.warning,
            error: "Link unbekannt"
          });
        } else {
          this.thismodel.crud = CrudType.updatechildbaseid;
          this.thismodel.child_base_id = model.base_id;
          this.thismodel.strvalue = model.search;
        }
      } else {
        this.thismodel.crud = CrudType.updatestrvalue;
        this.thismodel.strvalue =
          model.base_id != undefined ? model.search : model;
      }

      globalUpdateDetailService.sendMessage(this.thismodel);
    }
  }

  @Watch("strsearch")
  onPropertyChanged(value: any) {
    
    if (this.isactivated == false)  return;


    // Items have already been loaded
    if (this.items != undefined && this.items.length > 0) return;

    // Items have alread been requested
    //  if (this.isLoading) return;

    this.isLoading = true;
    var url = "Service/GetAutoCompleteValues";

    dataService.InitbyToken(store.getters.user.token);

    this.thismodel.filter = value;
    dataService
      .post<IStrDetailValue, IFullText[]>(url, this.thismodel)
      .then(res => {
        this.entries = res;
      })
      .catch(err => {
        globalInfoService.sendMessage(<IInfo>{
          msg: "Werte zum vervollständigen konnten nicht geholt werden.",
          type: InfoType.warning,
          error: err
        });
      })
      .finally(() => (this.isLoading = false));
  }

  items(): IFullText[] {
    return this.entries;
  }

  public data() {
    return {
      thismodel: this.model,
      autovalue: this.model ?  this.model.strvalue : null,
      descriptionLimit: 60,
      isLoading: false,
      currentval:  this.model ?  this.model.strvalue : null,
      canAction: false,
      isactivated: false,
    };
  }

  computed() {}

  mounted()
  {
    this.isactivated = true;

  }
}
